import { graphql, Link } from "gatsby";
import React from "react";
import { Button, Container, Form, InputGroup, Row, Col } from "react-bootstrap";
import Recaptcha from "react-google-recaptcha";
import Layout from "../components/Layout";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export default function ContactusPage({ data }) {
  const HeadingResource = data.HeadingResource.nodes;
  const recaptchaRef = React.createRef();
  const [buttonDisabled, setButtonDisabled] = React.useState(true);

  const [formState, setFormState] = React.useState({

    LastName: "",
    AreYou: "",
    FirstName: "",
    Message: "",
    Email: "",
    PhoneCode: "",
    Phone: "",
  });

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };
  const handleChange = (e) => {
    setFormState({
      ...formState,
      [e.target.name]: e.target.value,
    });
  };

  function GetValueByID(str) {
    var a = document.getElementById(str);
    if (a) return a.value;
    else {
      return "";
    }
  }

  const handleSubmit = e => {
    let x = GetValueByID("FirstName");
    let n = GetValueByID("Message");
    let y = GetValueByID("Email");
    let p = GetValueByID("Phone");
    let lastname = GetValueByID("LastName");
    let areYou = GetValueByID("AreYou");
    let phoneCode = document.querySelector(".react-tel-input input")?.value;

    if (x === "" || n === "" || y === "" || p === "") {
      e.preventDefault();
      return false;
    }
    const obj = {
      FirstName: x,
      Message: n,
      Email: y,
      Phone: phoneCode + p,
      LastName: lastname,
      AreYou: areYou,
    };
    const recaptchaValue = recaptchaRef.current.getValue();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "information", ...obj, 'g-recaptcha-response': recaptchaValue })
    })
      .then(() => alert("Thank you"))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  return (
    <Layout>
      <main>
        <Container>
          <section className="py-5">
            <div className="col-xl-8 col-lg-8 col-md-12 mx-auto">
              <Row>
                <div className="col-xl-5 col-lg-5 col-md-12">
                  <h1 className="fw-bold mb-4 fs-36 lh-46 pe-2">
                    We’re always happy to help!
                  </h1>
                  <h5 className="fs-20 bogo-par lh-32 fw-normal pe-4">
                    Submit your enquiry and we’ll get in touch within 48 hours
                  </h5>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12 ps-xl-5">

                  <form onSubmit={handleSubmit} name="information" method="post" data-netlify-recaptcha="true" data-netlify="true" data-netlify-honeypot="bot-field">

                    <input type="hidden" name="form-name" value="information" />

                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput12"
                    >
                      <Form.Label >
                        Are you a…
                      </Form.Label>
                      <Form.Select defaultValue="Choose..."
                        name="AreYou"
                        id="AreYou"
                        onChange={handleChange}
                        value={formState.AreYou}>
                        <option value="Business">Business</option>
                        <option value="Consumer">Consumer</option>
                        <option value="Social Media Influencer">Social Media Influencer</option>

                      </Form.Select>
                    </Form.Group>
                    <Row>
                      <Form.Group
                        as={Col}
                        className="mb-4"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="First Name *"
                          name="FirstName"
                          id="FirstName"
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        as={Col}
                        className="mb-4"
                        controlId="exampleForm.ControlInput2"
                      >
                        <Form.Control
                          type="text"
                          placeholder="Last Name (optional)"
                          name="LastName"
                          id="LastName"

                        />
                      </Form.Group>
                    </Row>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="email"
                        placeholder="Email address *"
                        name="Email"
                        id="Email"
                        required
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlInput3"
                    >
                      <InputGroup className="mb-4">
                        <PhoneInput country="ae" id="PhoneCode" enableAreaCodes={true} />
                        <Form.Control
                          type="tel"
                          name="Phone"
                          placeholder="Mobile number *"
                          id="Phone"
                          aria-label="Text input with dropdown button"
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Your message *"
                        name="Message"
                        id="Message"
                        required
                        rows={3}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-4"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Recaptcha
                        ref={recaptchaRef}
                        sitekey="6LeUanMcAAAAAAk3U8ieboKe0uqx1LNFb8i4QvUi"
                        size="normal"
                        id="recaptcha-google"
                        onChange={() => setButtonDisabled(false)}
                      />
                    </Form.Group>
                    <Button
                      className="w-100 fs-14 fw-bold"
                      type="submit"

                    >
                      Submit
                    </Button>
                  </form>
                </div>
              </Row>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="py-5">
          <section className="bg-light my-5 px-0 custom-h">
            <Row>
              {HeadingResource.map((node, index) => {
                return (
                  <>
                    <div className="col-xl-4 col-lg-4 col-md-12 ps-0">
                      <img
                        src={node.MultipleTitleWidgeImage}
                        className="footer-about"
                        alt="bg"
                      />
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 pt-xl-5 pt-lg-5 mt-xl-4 mt-lg-4 pt-5 mb-5 mt-xl-3 mt-lg-3 pe-xl-5 pe-2 ps-4 ps-xl-3">
                      <h3 className="fw-bold mb-5">
                        {node.MultipleTitleWidgetFirstTitle}
                      </h3>
                      <h5 className="bogo-par fw-500 lh-32 fw-airlight  mb-5 fw-airlight pe-5 me-5 pe-5 me-3">
                        {node.MultipleTitleWidgetFirstDescription}
                      </h5>
                      <Link
                        to={node.MultipleTitleWidgetFirstCTALink}
                        className="fs-18 link-color pb-2"
                      >
                        {node.MultipleTitleWidgetFirstCTAText}{" "}
                        <img
                          src="../img/arrow-right.svg"
                          alt="arrow right"
                          className="ms-3"
                        />
                      </Link>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 pt-xl-5 pt-lg-5 mt-xl-4 mt-lg-4 pt-5 mb-5  pe-xl-5 pe-2 ps-4 ps-xl-3">
                      <h3 className="fw-bold mb-5">
                        {node.MultipleTitleWidgetSecondTitle}
                      </h3>
                      <h5 className="bogo-par fw-500 lh-32 fw-airlight  mb-5 fw-airlight pe-5 me-5 me-3">
                        {node.MultipleTitleWidgetSecondDescription}
                      </h5>
                      <Link
                        to={node.MultipleTitleWidgetSecondCTALink}
                        className="fs-18 link-color pb-2"
                      >
                        {node.MultipleTitleWidgetSecondCTAText}{" "}
                        <img
                          src="../img/arrow-right.svg"
                          alt="arrow right"
                          className="ms-3"
                        />
                      </Link>
                    </div>
                  </>
                );
              })}
            </Row>
          </section>
        </Container>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query {
    HeadingResource: allResourceJson(
      filter: { MultipleTitleWidgetFirstTitle: { ne: null } }
    ) {
      nodes {
        MultipleTitleWidgeImage
        MultipleTitleWidgetFirstCTALink
        MultipleTitleWidgetFirstDescription
        MultipleTitleWidgetFirstCTAText
        MultipleTitleWidgetFirstTitle
        MultipleTitleWidgetSecondCTALink
        MultipleTitleWidgetSecondCTAText
        MultipleTitleWidgetSecondDescription
        MultipleTitleWidgetSecondTitle
      }
    }
  }
`;
